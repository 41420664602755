/*
 * @FileDescription: 公共api
 * @Author: zhaohongyang
 * @Date: 2022-03-02
 */
import { BASE_URL } from '@/service/request/config'

let api = {}
// 本地json
if (BASE_URL === './') {
  api = {
    rulesList: '/data/common/rulesList.json', // 筛选-法规分类
    areaList: '/data/common/areaList.json', // 筛选-行政区划
    effectLevelList: '/data/common/effectLevelList.json', // 筛选-效力级别
    institutionList: '/data/common/institutionList.json', // 筛选-发布机关
    timelinessList: '/data/common/timelinessList.json', // 筛选-时效性
    yearList: '/data/common/yearList.json', // 筛选-发布年份
    filterList: '/data/common/filterList.json', // 筛选-顶部筛选条件
    collectAction: '/common/collectAction', // 收藏
    sortList: '/fzzyk/common/sortList' //发布日期实施日期升降序
  }
  // 接口 （地址在vue.config.js配置）
} else {
  api = {
    loginCheckAlways200: '/sso/isLogin',
    logout:'/author/Logon/logout',
    listDictionary:'/sym/codeItemDefine/listDictionaryBatch',
    systemInfo: '/sym/config/showSystemConfig',
    systemInfoLastTime:'/sym/config/queryLastUpdateTime',
    searchList: '/common/search', // 筛选-模糊检索
    rulesList: '/common/rulesList', // 筛选-法规分类
    listDictionaryBatch: '/common/listDictionaryBatch',// 获取代码表 可以用,隔开获一个接口取多个代码表
    areaList: '/common/areaList', // 筛选-行政区划
    effectLevelList: '/common/effectLevelList', // 筛选-效力级别
    institutionList: '/common/institutionList', // 筛选-发布机关
    timelinessList: '/common/timelinessList', // 筛选-时效性
    yearList: '/common/yearList', // 筛选-发布年份
    filterList: '/common/filterList', // 筛选-顶部筛选条件
    institutionSearch: '/common/institutionSearch', // 发布机关模糊检索
    lazyInstitutionSearch: '/common/lazyInstitutionSearch', // 发布机关逐级加载
    experience: '/experience/submit', // 产品体验申请
    feedBack: '/fzzyk/lawFeedback/insertContent', // 意见反馈
    sortList: '/common/sortList', //发布日期实施日期升降序
    browsHistory: '/common/addBrowsHistory', //浏览历史
    download: '/common/download', //收藏
    fieldList: '/case/common/fieldList', //案例筛选-领域名称
    areasList: '/case/common/caseAreaList', //案例筛选-地域名称
    matterList: '/case/common/reconsiderMatterList', //案例筛选-复议事项
    closeTypeList: '/case/common/closeTypeList',//案例筛选-结案类型
    yearCount: '/judgment/count/year',//案件筛选-结案年份
    caseTypeCount: '/judgment/count/casetype',//案件筛选-案件类型
    decideYear: '/case/common/decideYear', //案例筛选-决定年份
    getCaseType: '/case/common/caseFormList',// 案例筛选-案件类型
    behaviorList: '/case/common/behaviorList', //案例筛选-行为类别
    sortValueList: '/case/common/sortValueList', //案例浏览量决定日期升降序
    recommendReason: '/case/common/recommendReason', //案例详情页-推荐理由
    caseCollect: '/lawCollection/addLawCollection', //案例-我的收藏
    // 人民法院案例库
    caseLibrarySearch: '/case/library/search',
    caseLibrarySearchGroup:'/case/library/search/group',
    caseLibraryGroupCount: '/case/library/count/group',
    caseLibraryDetail: '/case/library/detail',
    caseLibraryRelateLaws: '/case/library/relateLaws',
  }
}
export default api
