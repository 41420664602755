/*
 * @Author: zhaohongyang
 * @Date: 2022-03-02 10:09:00
 * @Description: 文件描述
 */
import { createStore } from 'vuex'

export default createStore({
  state: {
    form: {},
    searchList: [],
    curFlag1: false,
    formFlag: undefined,
    totalCount: 0,
    caseLibrary: {
      total: 0,
      headerApiParams: [] as any[],
      resultApiParam: [] as any[]
    }
  },
  getters: {
    getCaseLibraryApiParam(state) {
      const param: any = {}
      const conds = [...state.caseLibrary.headerApiParams, ...state.caseLibrary.resultApiParam]
      conds.forEach(item => {
        if (['ajzh', 'cprqStart', 'cprqEnd', 'cprqYear'].includes(item.param)) {
          param[item.param] = Array.isArray(item.value) ? item.value[0] : item.value
        } else {
          param[item.param] = [...(param[item.param] || []), ...(Array.isArray(item.value) ? item.value : [item.value])]
        }
      })
      return param
    }
  },
  mutations: {
    addResultApiParam(state, param: any) {
      if (param.value && param.value.length > 0 && !state.caseLibrary.headerApiParams.find((item: any) => item.id === param.id)) {
        if (param.replace) {
          state.caseLibrary.headerApiParams = state.caseLibrary.headerApiParams.filter(item => item.param !== param.param)
        }
        state.caseLibrary.headerApiParams.push(param)
      }
    },
    addHeaderApiParam(state, param: any) {
      if (param.value && param.value.length > 0 && !state.caseLibrary.headerApiParams.find((item: any) => item.id === param.id)) {
        state.caseLibrary.headerApiParams.push(param)
      }
    },
    caseLibraryClearCondition(state, conditionId) {
      state.caseLibrary.headerApiParams = state.caseLibrary.headerApiParams.filter((item: any) => item.id !== conditionId)
      state.caseLibrary.resultApiParam = state.caseLibrary.resultApiParam.filter((item: any) => item.id !== conditionId)
    },
    caseLibraryClearAllCondition(state) {
      state.caseLibrary.headerApiParams = []
      state.caseLibrary.resultApiParam = []
    },
    setForm(state, form) {
      state.form = form
    },
    setSearchList(state, list) {
      state.searchList = list
    },
    setFlag(state, flag) {
      state.curFlag1 = flag
    }
    ,
    setFormFlag(state, flag) {
      state.formFlag = flag
    },
    setTotal(state, count) {
      state.totalCount = count
    }
  },
  actions: {},
  modules: {}
})
