/*
 * @Author: zhaohongyang
 * @Date: 2022-03-02 10:48:41
 * @Description: 文件描述
 */
import { App } from 'vue'
import 'element-plus/dist/index.css'

import {
  ElAffix,
  ElTabs,
  ElTabPane,
  ElButton,
  ElRadio,
  ElRate,
  ElForm,
  ElFormItem,
  ElSelect,
  ElRadioGroup,
  ElCheckbox,
  ElOption,
  ElInput,
  ElCard,
  ElIcon,
  ElDatePicker,
  ElConfigProvider,
  ElDropdown,
  ElPopover,
  ElPagination,
  ElRow,
  ElCol,
  ElTable,
  ElTree,
  ElTreeSelect,
  ElAutocomplete,
  ElDialog,
  ElMessage,
  ElTooltip,
  ElCascader,
  ElLoading,
  ElScrollbar,
  ElTag,
  ElMenu,
  ElMenuItem,
  ElSubMenu
} from 'element-plus/lib/components'

const components = [
  ElAffix,
  ElTabs,
  ElTabPane,
  ElButton,
  ElRadio,
  ElForm,
  ElRate,
  ElFormItem,
  ElSelect,
  ElRadioGroup,
  ElCheckbox,
  ElOption,
  ElInput,
  ElCard,
  ElIcon,
  ElDatePicker,
  ElConfigProvider,
  ElDropdown,
  ElPopover,
  ElPagination,
  ElRow,
  ElCol,
  ElTable,
  ElTree,
  ElTreeSelect,
  ElAutocomplete,
  ElDialog,
  ElTooltip,
  ElMessage,
  ElCascader,
  ElLoading,
  ElScrollbar,
  ElTag,
  ElMenu,
  ElMenuItem,
  ElSubMenu
]
export default function (app: App): void {
  components.forEach((component) => {
    app.use(component)
  })
}
