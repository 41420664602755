/*
 * @Author: zhaohongyang
 * @Date: 2022-03-02 10:24:10
 * @Description: 文件描述
 */
// service统一出口
import httpRequest from './request'
import { BASE_URL, FZZYK_URL, LEGISLATE_URL } from './request/config'

export default new httpRequest({
  // 准备离职就乱改是吧
  // baseURL:'http://192.168.2.46:18082/fzzyk',
  baseURL: BASE_URL,
  // timeout: TIME_OUT,
  interceptors: {
    requestInterceptor: (config) => {
      return config
    },
    requestInterceptorCatch: (err) => {
      return err
    },
    responseInterceptor: (res) => {
      return res
    },
    responseInterceptorCatch: (err) => {
      return err
    }
  }
})

export const fzzykRequest = new httpRequest({
  baseURL: FZZYK_URL,
  interceptors: {
    requestInterceptor: (config) => {
      return config
    },
    requestInterceptorCatch: (err) => {
      return err
    },
    responseInterceptor: (res: any) => {
      return res
    },
    responseInterceptorCatch: (err) => {
      return err
    }
  }
})
export const legislateRequest = new httpRequest({
  baseURL: LEGISLATE_URL,
  interceptors: {
    requestInterceptor: (config) => {
      return config
    },
    requestInterceptorCatch: (err) => {
      return err
    },
    responseInterceptor: (res: any) => {
      return res
    },
    responseInterceptorCatch: (err) => {
      return err
    }
  }
})
