/*
 * @Author: wangjie
 * @Date: 2022-03-07 16:58:48
 * @Description: 案例详情页api
 */

export default {
  detailCasePageList: '/case/detailCasePage/detailList',//案例详情页
  judgeCollection:'/lawCollection/judgeCollection',//法律收藏
  judgeCollection2:'/case/lawCollection/caseCollections',//行政案例收藏
  judgeCollection3:'/caseLibrary/collection/isCaseLibraryCollections',//司法案例收藏
  judgeCollection4:'/judgment/collection/judgmentCollection',//文书收藏
}
