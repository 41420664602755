/*
 * @FileDescription: 公共api
 * @Author: zhaohongyang
 * @Date: 2022-03-02
 */
import { BASE_URL } from '@/service/request/config'
let api = {}
// 本地json
if (BASE_URL === './') {
  api = {
    searchListTotalList: '/data/searchListPage/totalList.json', //查询列表页-总体数量
    searchListLawList: '/data/searchListPage/lawList.json' //查询列表页-列表
  }
  // 接口 （地址在vue.config.js配置）
} else {
  api = {
    searchListTotalList: '/searchListPage/totalList', //查询列表页-总体数量
    searchListLawList: '/searchListPage/lawList', //查询列表页-列表
    historyList: '/fzzykLawHistory/queryByLawId', //查询列表页-历史沿革
    hostLawList: '/FzzykLawSuperior/queryLawId', //查询列表页-上位法
  }
}
export default api
