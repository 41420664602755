/*
 * @FileDescription: 公共api
 * @Author: zhaohongyang
 * @Date: 2022-03-02
 */
import { BASE_URL } from '@/service/request/config'
let api = {}
// 本地json
if (BASE_URL === './') {
  api = {
    homeDetailList: '/data/home/detailList.json', // 法律法规资源-列表
    homeEnforceCase: '/data/home/enforceCase.json', // 案例资源-行政执法案例
    homeReconsiderCase: '/data/home/reconsiderCase.json', // 案例资源-行政复议案例
    homeBusinessList: '/data/home/businessList.json' // 业务赋能
  }
  // 接口 （地址在vue.config.js配置）
} else {
  api = {
    homeRulesList: '/home/rulesList', // 筛选-法规分类
    homeAreaList: '/home/areaList', // 筛选-行政区划
    homeEffectLevelList: '/home/effectLevelList', // 筛选-效力级别
    homeDetailList: '/home/detailList', // 法律法规资源-列表
    homeEnforceCase: '/home/enforceCase', // 案例资源-行政执法案例
    homeReconsiderCase: '/home/reconsiderCase', // 案例资源-行政复议案例
    homeBusinessList: '/home/businessList' // 业务赋能
  }
}
export default api
