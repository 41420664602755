export function requireAnyRole(roles: any) {
  const loginInfoStr = localStorage.getItem('loginInfo')
  if (loginInfoStr) {
    const loginInfo = JSON.parse(loginInfoStr)
    let userRoles = loginInfo.user.roles || []
    let intersection = roles.filter(v => userRoles.includes(v))
    if (intersection.length > 0) {
      return true
    }
  }
  return false
}