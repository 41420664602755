/*
 * @Author: lailifang
 * @Date: 2022-03-05 14:58:06
 */

import { BASE_URL } from '@/service/request/config'
let api = {}
// 本地json
if (BASE_URL === './') {
  api = {
    myCollectTable: '/data/Collection/myCollect' // 我的收藏页-列表
  }
  // 接口 （地址在vue.config.js配置）
} else {
  api = {
    myCollectTable: '/lawCollection/collectionByName', // 法律收藏页-列表
    myCollectTable2: '/case/lawCollection/collectionByName', // 行政案例收藏页-列表
    myCollectTable3: '/caseLibrary/collection/getCaseLibraryCollections', // 司法案例收藏页-列表
    myCollectTable4: '/judgment/collection/getJudgmentCollections',// 裁判文书收藏页-列表
    collectAction: '/lawCollection/addLawCollection', // 取消法律收藏
    collectAction2: '/case/lawCollection/addCaseLawCollection', // 取消行政案例收藏
    collectAction3: '/caseLibrary/collection/caseLibraryCollections', // 取消司法案例收藏
    collectAction4: '/judgment/collection/judgmentCollections', // 取消裁判文书收藏
    myFeedbackTable:'/lawFeedback/queryByCondition',//我的反馈
    cancelFeedback:'/lawFeedback/deleteContent',//删除反馈
  }
}
export default api
