/*
 * @Author: wangjie
 * @Date: 2022-07-14 15:51:07
 * @Description: 文件描述
 */
import { createApp } from 'vue'
import { globalRegister } from './global'

import 'normalize.css'
import './assets/css/index.less'
import './theme/style.less'

import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App)

app.use(globalRegister) // 全局注册

app.use(store)
app.use(router)

app.mount('#app')
