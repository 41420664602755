/*
 * @Author: wangjie
 * @Date: 2022-03-07 16:58:48
 * @Description: 详情页api
 */

export default {
  downloadFile: '/detailPage/download', // 下载附件
  detailPageList: '/detailPage/detailList', //详情页
  detailPageRelatedLaws: '/detailPage/relatedLaws', //详情页-相关法律
  detailPageRelatedCase: '/case/library/relateCases', //详情页-相关案例
  detailPageReferenceLaws: '/detailPage/referenceLaws', //详情页-引用法规
  detailPageKnowledgeMap: '/neo4j/getLawAndTagDatas', // 知识图谱
  lawSummary: '/detailPage/lawSummary' // 法律摘要
}
